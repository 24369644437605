export default {
  "message": {
    "navbar": {
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface language"])},
      "queues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active queues"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the project"])}
    },
    "main": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
      "eQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic queue"])},
      "scanQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code at the entrance of the establishment to join the queue"])},
      "connectQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Queue"])},
      "completeQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Service"])},
      "dropQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Queue"])},
      "dropQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to leave the queue, please free your place in it."])},
      "skipQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip Queue"])},
      "skipQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not have time to arrive at the place, skip one customer ahead of you."])},
      "selectQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select active queue"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understood"])},
      "youNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are the next customer"])},
      "nowQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your queue"])},
      "nowQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed to the seat while the current customer is being served."])},
      "yourPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Service Number"])},
      "beforeYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["There is nobody before you"]), _normalize(["There are ", _interpolate(_named("n")), " persons before you"])])},
      "afterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["There is nobody after you"]), _normalize(["There are ", _interpolate(_named("n")), " persons after you"])])},
      "averageService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average service time"])},
      "averageWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approximate waiting time"])},
      "forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast of the start of your service"])},
      "currentService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current client is being serviced"])},
      "statInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated approximately based on service data of recent customers."])},
      "userdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form"])},
      "userdataInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out the form while you are in the queue. This will speed up service."])},
      "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful information"])},
      "userInfoInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the useful information while you are in the queue."])},
      "userPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "userPayInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pay for your order online while you are in line. This will speed up service."])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all application data"])},
      "worktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working time today"])},
      "timebreak": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["No breaks"]), _normalize(["Break"]), _normalize(["Breaks"])])},
      "booktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queuing allowed"])},
      "landingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.queues.top/en"])}
    },
    "dialogs": {
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "okQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for contacting us!"])},
      "drpQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left the queue."])},
      "skpQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have given up your queue. Thank you."])},
      "cnctQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for contacting us! Please monitor the current state of the queue and calculate your time to get to your seat on time."])},
      "cnctQueueHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information"])},
      "cnctQueueSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This queue allows you to <i>give up your place</i> to someone behind you if you don't arrive on time."])},
      "cnctQueuePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This queue allows you to <i>make a prepayment</i>."])},
      "cnctQueueReconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not close this web page while you are in the queue. Save its link or install it as an app. If you do lose it, visit our website <a href=\"https://www.queues.top\">www.queues.top</a> and select «Am I in a queue?» from the main menu."])},
      "userdataInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please <i>fill out the form</i> while you are in line. This will speed up service."])},
      "cnctQueueDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your plans change, please leave the queue by clicking the appropriate button."])},
      "cnctQueuePush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be notified when a client in front of you starts a service. This requires that the app or web page and your browser have <i>permission to receive push notifications.</i> We promise do not use this opportunity for anything that is not related to being in this queue."])},
      "apiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection error. Please try again later."])},
      "impossibleToSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is impossible to give up a turn."])},
      "impossibleIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to join this queue now."])},
      "queueNoExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No such queue exists"])},
      "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
      "notInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not in this queue."])},
      "alreadyComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already left the queue."])},
      "notYourQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not your queue now."])},
      "dropConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your intention to leave this queue."])},
      "wipeConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your intention to delete all data."])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete data"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push notification"])},
      "pushInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order for the application to work properly, it is necessary to allow the receipt of push notifications. If they are prohibited, you will have to monitor the status of the queue yourself."])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot"])},
      "firebase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registering for the push notification service. Please wait."])}
    }
  }
}